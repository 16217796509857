.chat-starters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;
  display: none;
}

.chat-suggesst-wrapper {
  /* min-height: 360px; */
  min-height: 270px;
}

.start-chat-suggesst-title {
  display: none;
}

.chat-suggesst-title-text {
  margin-bottom: 20px;
  width: 60%;
  line-height: 28px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.chat-suggesst-title-inner {
  gap: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.start-suggession-starter {
  position: unset !important;
}



@media (max-width: 767px) {
  .chat-starters {
    /* display: grid; */
    grid-template-columns: repeat(1, 1fr);
    display: none;
  }

  .chat-suggesst-wrapper {
    /* min-height: 430px; */
    min-height: 270px;
  }

  .chat-suggesst-title-text {
    margin-bottom: 20px;
    width: 60%;
    line-height: 28px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }
}

.suggetion-container button {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #8ecc3d;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: #575E71;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.suggetion-container button:hover {
  background: #f5f5f5;
}

.suggetion-container {
  height: 100%;
}

.suggetion-wrapper {
  height: 100%;
  min-height: 50px;
}

.suggetion-icon img {
  width: 16px;
  padding-top: 3px;
}

.suggetion-icon {
  display: flex;
  padding-left: 8px;
}

.upload-img-wrapper {
  margin: auto auto 0px;
  height: 50px;
}

.central-column-bbtn-wrapper {
  position: absolute;
  top: 45px;
  width: 100%;
  left: 0;
  z-index: 100;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #ffffff85;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

@media (min-width: 767px) {
  .central-column-bbtn {
    height: 50px;
  }
}


.image-upload {
  padding: 7px 2px 2px !important;
}

.start-central-column-bbtn-wrapper {
  display: none;
}

.image-upload img {
  width: 35px;
  margin-right: 4px;
  padding: 6.5px;
}

.image-upload img:hover {
  background: rgba(0, 0, 0, 0.15);
}

.comman-button-text-input-img:hover {
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.40); */

}


.chat-help-btns-stage {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  gap: 8px;
}

.chat-help-btn-img {
  width: 100%;
  max-width: 110px;

}

.central-column-btn-middle-img {
  width: 32px;
  height: 32px;
  margin-top: -2px;
  margin-right: 5px;
}


.central-column-bbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  width: 100%;

}

.central-greeting-user {
  display: flex;
  align-items: center;
  gap: 5px;

}

.central-column-btn-middle-container {
  display: flex;
  align-items: center;
}

.left-column .video-wrapper video {
  /* width: 100%;
  height: 80%;
  position: absolute;
  top: 80px;
  left: 6%;
  border-radius: 12px;
  overflow: hidden;
  z-index: 10; */
}

.central-column-btn-middle-container span {
  font-weight: 500;
  line-height: 18px;
}

.central-column-btn-middle-wrapper {
  padding-right: 12px;
  padding-left: 12px;
  width: 30%;
  display: flex;
  justify-content: center;
}

.main-three-colum-layout-wrapper {
  height: 100%;
  width: 100%;
}

.main-three-colum-layout-container {
  height: 100%;
  width: 100%;
}

.central-column-btn-back {
  width: 37px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.15);
}

@media (max-width: 767px) {
  .central-column-bbtn-wrapper {
    top: 55px;
    height: 60px;

  }

  /* .central-column-btn-middle-wrapper {
    width: 55%;
    justify-content: left;
  } */

  .central-column-btn-right {
    display: none;
  }

  .chat-help-btns-wrapper {

    margin-top: 5px;
  }

  .central-column-btn-wrapper {
    margin: 5px 5px 5px 18px;
    height: 70px;
  }

  .central-column-btn-back {
    width: 45px;
    /* margin-right: 10px; */
    margin-bottom: 1px;
    padding: 12px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.20);
  }
}



.central-column-btn-back:hover {
  background: rgba(0, 0, 0, 0.20);
}

.central-column-btn-back span {
  font-size: 15px;
  font-weight: 500;
}

.central-column-btn-wrapper:hover {
  /* background:#ffffffd1 */
}

.central-column-btn-wrapper {
  background: transparent;
  width: 30%;
  margin: 4px 2px 2px 0px;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;

}

.central-column-btn-wrapper span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.chat-help-btn1 {
  max-width: 90px;
  width: auto;
  max-height: 110px;
  height: 100%;
  border: none;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

/* @media (max-width: 767px) {
.chat-help-btn-img-st2 {
  min-width: 60px!important;
}
} */

.chat-help-btn-img-st2 {
  min-width: 40px;
  width: 100%;
  max-width: 45px;
}

@media (min-width: 992px) {
  .chat-help-btns-wrapper {
    width: 100%;
  }

  .stage-2-3-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .stage-2,
  .stage-3 {
    padding-left: 0px !important;
    padding-right: 8px !important;
    width: 50%;
  }

  .chat-help-btns-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stage-1 {
    width: 70%;
    padding-bottom: 10px;
  }

  .chat-help-btns-container .stage-3 button {
    /* width: auto !important; */
  }

  .stage-3 button,
  .stage-2 button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .stage-3 .button-hover:hover,
  .stage-2 .button-hover:hover {
    background: #fafafa !important;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, .12) !important;
  }
}

.chat-help-btns-container .stage-2 button,
.chat-help-btns-container .stage-3 button {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}


.chat-help-btns-container .stage-2 .button-wrapper div,
.chat-help-btns-container .stage-3   .button-wrapper  div {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .12);
  padding: 10px;
  width: 100%;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 10px;
  min-width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(1.5px);
-webkit-backdrop-filter: blur(1.5px); */
}

.chat-help-btns-container .stage-2 .button-wrapper, .chat-help-btns-container .stage-3 .button-wrapper{
  width: 100%;
  max-width: 108px;
  min-width: 80px;
  background: rgba(255, 255, 255, 0.80);
  border-radius: 18px;
}

.chat-help-btns-stage .button:focus-visible {
  outline: none;
}

.stage-2 span,
.stage-3 span {
  color: #1A3054;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.stage1-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stage1-btn-wrapper span {
  text-align: center;
  color: #1A3054;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 200% */
}

.chat-help-btns-wrapper {
  display: block !important;
  width: 100%;

}

@media (min-width: 768px) {
  .chat-help-btns-wrapper {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 960px;
  }
}

.chat-help-btns-wrapper-none {
  display: none;
}



.stage-2,
.stage-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #5792ff;
  border-radius: 4px;
  margin-top: -160px;
  /* Adjust this value as needed */
  z-index: 1;
  list-style-type: none;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;

}

.autocomplete-item {
  padding: 8px 16px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.input-container-wrapper {
  width: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  align-items: center;
}

.q-and-a-container-wrapper {
  align-items: flex-end;
  min-height: 100vh;
  width: 100%;
  display: flex;

}

.start-q-and-a-container {
  max-height: 100vh !important;
  min-height: unset !important;
}

/* start page */
.start-main-inner-container {
  border-radius: 12px !important;
  /* align-items: center!important; */
  margin: 0px !important;
  overflow: unset !important;
}

.main-background-container .start-container {
  border-radius: 12px !important;
  align-items: center !important;
}

.start-output-area {
  display: none !important;
}

.start-main-background-container {
  /* height: 25% !important */
}

@media (min-width: 1150px) {

  .main-three-colum-layout .start-right-column {
    position: absolute !important;
  }
}

.start-form {
  /* margin-top: 45vh!important; */
}

.start-input-container {
  width: 100% !important;
  max-width: 720px;
  margin-bottom: 0px !important;
}

/* .q-and-a-container  {
  margin-top: 200px!important;
} */

div.main-q-a-box:first-child {
  margin-top: 50px;
}

@media (max-width: 992px) {

  .start-main-three-colum-layout {
    background: #e9f2ff;
  }
}

/* ::-webkit-scrollbar {
  display: none;
}  */

/* ::-webkit-scrollbar {
  width: 25px; 
}  */



.main-background-container .main-inner-container .kps-chat-form .input-container .submit-button .chat-logo-record {

  height: 90%;
  width: 90%;
  max-width: 40px;
  margin: 5px;
  color: #333;
  background: #fff;
  box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: 250ms color;
  -webkit-transition: 250ms color;
  -moz-transition: 250ms color;
  -ms-transition: 250ms color;
  -o-transition: 250ms color;
  animation-name: wave;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes wave {
  0% {
    box-shadow: 0 0 0px 0px rgba(19, 108, 251, 0.5);
  }

  100% {
    box-shadow: 0 0 0px 8px rgba(255, 255, 255, 0);
  }
}


.bacground-client-msg-bubble {
  background: #ADC1E0 !important;
  padding-right: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 11px !important;

}

/*  */

.left-client-msg-bubble {
  justify-content: flex-start !important;
  margin-left: 0px !important;
  /* margin-bottom: -30px; */
}

.left-client-msg-bubble .client-questions {
  border-radius: 21px 21px 21px 0px !important;

}

scroll-container {
  overflow-y: hidden;
  /* Hide the default scrollbar */
  /* height: 1300px; Adjust the height as needed */
  will-change: transform;
  /* Improve performance by indicating that the transform property will change */
  border: 1px solid #ccc;
  /* Add a border for visualization */
  position: relative;
}

.voice-indicator {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.2s ease-in-out;
}

.voice-indicator.active {
  background-color: #00cc00;
}


.submit-voice {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.submit-wrapper {
  margin: auto auto 0px;
  height: 50px;
}

.comman-button-text-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  border: none;
  background: transparent;
  border-radius: 25px;
  cursor: pointer;
  padding: 0px 4px 1px 1px;
}

.comman-button-text-input-img {
  max-width: 46px;
  width: 45px;
  background: transparent;
  border-radius: 11px;
}

.bot-msg-bubble-container {
  background: #0C2159;
  border-radius: 21px 21px 21px 3px;
  display: flex;
  padding: 15px 18px 15px 15px;
}

.bot-msg-text {
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65ch;
}

.voice-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #3498db;
  color: #fff;
  font-size: 24px;
}

.sharebtn-wrapper {
  width: 30%;
  display: flex;
  justify-content: center;

}


.sharebtn-inner {
  width: 98%;
  display: flex;
  margin-left: 21px;
  position: relative;
  top: 0px;
  justify-content: center;
}

@media screen and (max-width: 767px) {}

.sharebtn .sharebtn-img {
  background: #ffffffb0;
  margin-right: 38px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 8px 8px 8px;
  border-radius: 6px;
  opacity: 0.75;
  width: 86px;
  height: 33px;
  font-size: 16px;
  font-weight: 600;
  font-family: "roboto", sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 1px 1px -2px rgba(0, 0, 0, .06), 0 1px 5px 0 rgba(0, 0, 0, .06);
}

.sharebtn .sharebtn-img:hover {
  background: #ffffff;
}

.sharebtn .sharebtn-img:hover {
  opacity: 1;

}

.share {
  width: 21px;
  cursor: pointer;
}

.sharebtn {
  margin-right: -167px;
}

@media (max-width: 767px) {
  .sharebtn {
    margin-right: 0px;
  }

  .sharebtn .sharebtn-img {
    margin-right: 0px;
  }

  .sharebtn-wrapper {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
    justify-content: right;
  }

  .sharebtn-inner {
    margin-left: 40px;
    width: auto;
  }

  .central-column-btn-middle-wrapper {
    justify-content: left;
    width: 50%;
  }


  .tooltiptext {
    display: none;
  }
}

.central-column-bbtn-inner {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}