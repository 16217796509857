.share-container {
    text-align: center;
    max-width: 1200px;
    width: 100%;
    position: relative;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #fff;
}

.title {
    font-weight: bold;
}

.main-title {}

.share-middle-container-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.share-icon {
    width: 230px;
    margin-left: 5px;
    vertical-align: middle;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.date {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
}


.conversation-list {
    height: calc(100vh - 165px);
    margin-bottom: 0px;
    margin-top: 2px;
    list-style: none;
    padding: 0;
    min-width: 200px;
    padding: 0;
    min-width: 200px;
    /* max-height: 600px; */
}

.message-container {
    /* border: 1px solid #ccc; */
    /* margin: 10px;
    padding: 10px; */
    text-align: center;
}

.message-icon {
    margin-right: 5px;
    vertical-align: middle;
}

.user-message .title,
.knightingale-message .title {

    margin-right: 5px;
}

.user-message,
.knightingale-message {
    /* margin-bottom: 15px; */
    text-align: left;
    font-weight: normal;
}

.user-message .message-icon,
.knightingale-message .message-icon {
    display: inline-block;
    width: 20px;
    text-align: center;
}

.response {
    margin: 0;
}

.message-icon {
    margin-right: 5px;
    vertical-align: middle;
    width: 20px;

}

.knightingale-message img {
    width: 33px !important;
    padding-bottom: 10px;
}

.knightingale-message .title {
    padding-bottom: 10px;
}

.user-message .message-icon,
.knightingale-message .message-icon {
    display: inline-block;
    text-align: center;
}

.knightingale-message-details {
    display: flex;
    gap: 10px;
}

.user-message .message-icon img,
.knightingale-message .message-icon img {
    width: 100%;
}


.user-message-container {
    display: flex;
    justify-content: flex-end;
}

.knightingale-message-container {
    display: flex;
    justify-content: flex-start;
}

.bot-answer {
    max-width: 70ch;
    flex-direction: column;
}

.client-question {
    max-width: 70ch;
}

.share-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    background: #f0f2f6;
    flex-direction: column;
    align-items: center;
}

.start-sharebtn-wrapper {
    /* display: none; */
}

.share_container {
    display: flex;
    width: 100%;
}

.play-button-container {
    width: 100%;
}

.share_knightigale_wrapper {
    width: 30%;
    max-height: 496px;
}

.play-audio-button {
    background: transparent;
    border: none;
    padding-left: 0px;
}

.play-audio-button img {
    width: 90px !important;
    padding-bottom: 0px;
    height: 34px;
    cursor: pointer;
}

.share_knightigale_wrapper video {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 600px;
    padding-top: 30px;
}

.share_knightingale_conversation {
    width: 70%;
    padding: 0px 15px;
}

.types_option {
    /* margin-right: 10px; */
}

.type_option_container {
    /* margin-right: 10px; */
}

.type_option_inner {
    padding: 6px 10px;
}

.share_wrapper {
    display: flex;
}

.type_option_text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 19.2px */
}

.type_option_img img {
    width: 32px;
    height: 32px;
}

.type_option_inner {
    display: flex;
    gap: 8px;
    border-radius: 10px;
    min-width: 180px;
    justify-content: center;
}

.types_container {
    display: flex;
    justify-content: space-between;
}

.types_details {

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}

.types_details_date {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
}


.types_details_title {
    color: #293041;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    padding: 0px 15px;
}

.types_details_date p {
    color: #858b97;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 10px 0px;
}



.share_footer_btn {
    padding: 10px;
    background: linear-gradient();
    width: 100%;

}

.share_footer_btn_container{
    width: 100%;
}
.share_footer_btn a {
    display: flex;
width: 100%;
height: 65px;
    padding: 12px 15px;
    border-radius: 15px;
    background: linear-gradient(90deg, #32A0E8 0.89%, #4765E0 98.66%);
    text-decoration: none;
    border-radius: 10px;
    font-family: "roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    align-items: center;
    color: #ffffff;
    word-wrap: break-word;
    text-align: center;
    justify-content: center;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.share_footer {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .share-container {
        padding-top: 20px;
    }
    .share_knightigale_wrapper {
        display: none;
        /* Hide the div on small screens */
    }

    .share_knightingale_conversation {
        width: 100%;
    }

    .types_container {
        flex-direction: column;
    }

    .types_details {
        display: flex;
        justify-content: center;
    }

    .types_details_date {
        justify-content: center;
    }

    .types_details_title {
        text-align: center;
    }


    .share_footer {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

@media (min-width: 767px) {
    .types_wrappper{
        padding-top: 20px;
    }
    .share_footer_btn_container {
        display: flex;
        width: 70%;
        justify-content: center;
    }
    .types_option {
        margin-right: 10px;
    }
}