.chat-thread > *:nth-child(1).message-reply + .message,
.chat-thread > * > .message:nth-child(1).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(1):not(.message-reply),
.chat-thread > * > .message:nth-child(1):not(.message-reply) {
  opacity: 0;
  animation: fadeIn .3s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(1) p,
.chat-thread > * > .message:nth-child(1) p {
  margin: 0;
  animation: 1.01s appear 0s forwards;
}
.chat-thread > *:nth-child(1) .typing-indicator,
.chat-thread > * > .message:nth-child(1) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 1s forwards;
}
.chat-thread > *:nth-child(1) .typing-indicator span,
.chat-thread > * > .message:nth-child(1) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(1) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(1) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(1) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(1) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(1) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(1) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(2).message-reply + .message,
.chat-thread > * > .message:nth-child(2).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(2):not(.message-reply),
.chat-thread > * > .message:nth-child(2):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.chat-thread > *:nth-child(2) p,
.chat-thread > * > .message:nth-child(2) p {
  margin: 0;
  animation: 4.01s appear 0s forwards;
}
.chat-thread > *:nth-child(2) .typing-indicator,
.chat-thread > * > .message:nth-child(2) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 4s forwards;
}
.chat-thread > *:nth-child(2) .typing-indicator span,
.chat-thread > * > .message:nth-child(2) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(2) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(2) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(2) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(2) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(2) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(2) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(3).message-reply + .message,
.chat-thread > * > .message:nth-child(3).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(3):not(.message-reply),
.chat-thread > * > .message:nth-child(3):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
.chat-thread > *:nth-child(3) p,
.chat-thread > * > .message:nth-child(3) p {
  margin: 0;
  animation: 5.01s appear 0s forwards;
}
.chat-thread > *:nth-child(3) .typing-indicator,
.chat-thread > * > .message:nth-child(3) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 5s forwards;
}
.chat-thread > *:nth-child(3) .typing-indicator span,
.chat-thread > * > .message:nth-child(3) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(3) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(3) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(3) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(3) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(3) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(3) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(4).message-reply + .message,
.chat-thread > * > .message:nth-child(4).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(4):not(.message-reply),
.chat-thread > * > .message:nth-child(4):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}
.chat-thread > *:nth-child(4) p,
.chat-thread > * > .message:nth-child(4) p {
  margin: 0;
  animation: 6.01s appear 0s forwards;
}
.chat-thread > *:nth-child(4) .typing-indicator,
.chat-thread > * > .message:nth-child(4) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 6s forwards;
}
.chat-thread > *:nth-child(4) .typing-indicator span,
.chat-thread > * > .message:nth-child(4) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(4) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(4) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(4) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(4) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(4) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(4) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(5).message-reply + .message,
.chat-thread > * > .message:nth-child(5).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(5):not(.message-reply),
.chat-thread > * > .message:nth-child(5):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
}
.chat-thread > *:nth-child(5) p,
.chat-thread > * > .message:nth-child(5) p {
  margin: 0;
  animation: 7.01s appear 0s forwards;
}
.chat-thread > *:nth-child(5) .typing-indicator,
.chat-thread > * > .message:nth-child(5) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 7s forwards;
}
.chat-thread > *:nth-child(5) .typing-indicator span,
.chat-thread > * > .message:nth-child(5) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(5) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(5) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(5) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(5) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(5) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(5) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(6).message-reply + .message,
.chat-thread > * > .message:nth-child(6).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(6):not(.message-reply),
.chat-thread > * > .message:nth-child(6):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 6s;
}
.chat-thread > *:nth-child(6) p,
.chat-thread > * > .message:nth-child(6) p {
  margin: 0;
  animation: 8.01s appear 0s forwards;
}
.chat-thread > *:nth-child(6) .typing-indicator,
.chat-thread > * > .message:nth-child(6) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 8s forwards;
}
.chat-thread > *:nth-child(6) .typing-indicator span,
.chat-thread > * > .message:nth-child(6) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(6) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(6) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(6) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(6) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(6) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(6) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(7).message-reply + .message,
.chat-thread > * > .message:nth-child(7).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(7):not(.message-reply),
.chat-thread > * > .message:nth-child(7):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 7s;
}
.chat-thread > *:nth-child(7) p,
.chat-thread > * > .message:nth-child(7) p {
  margin: 0;
  animation: 9.01s appear 0s forwards;
}
.chat-thread > *:nth-child(7) .typing-indicator,
.chat-thread > * > .message:nth-child(7) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 9s forwards;
}
.chat-thread > *:nth-child(7) .typing-indicator span,
.chat-thread > * > .message:nth-child(7) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(7) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(7) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(7) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(7) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(7) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(7) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(8).message-reply + .message,
.chat-thread > * > .message:nth-child(8).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(8):not(.message-reply),
.chat-thread > * > .message:nth-child(8):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}
.chat-thread > *:nth-child(8) p,
.chat-thread > * > .message:nth-child(8) p {
  margin: 0;
  animation: 10.01s appear 0s forwards;
}
.chat-thread > *:nth-child(8) .typing-indicator,
.chat-thread > * > .message:nth-child(8) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 10s forwards;
}
.chat-thread > *:nth-child(8) .typing-indicator span,
.chat-thread > * > .message:nth-child(8) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(8) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(8) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(8) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(8) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(8) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(8) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(9).message-reply + .message,
.chat-thread > * > .message:nth-child(9).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(9):not(.message-reply),
.chat-thread > * > .message:nth-child(9):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 9s;
}
.chat-thread > *:nth-child(9) p,
.chat-thread > * > .message:nth-child(9) p {
  margin: 0;
  animation: 11.01s appear 0s forwards;
}
.chat-thread > *:nth-child(9) .typing-indicator,
.chat-thread > * > .message:nth-child(9) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 11s forwards;
}
.chat-thread > *:nth-child(9) .typing-indicator span,
.chat-thread > * > .message:nth-child(9) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(9) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(9) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(9) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(9) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(9) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(9) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(10).message-reply + .message,
.chat-thread > * > .message:nth-child(10).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(10):not(.message-reply),
.chat-thread > * > .message:nth-child(10):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 10s;
}
.chat-thread > *:nth-child(10) p,
.chat-thread > * > .message:nth-child(10) p {
  margin: 0;
  animation: 12.01s appear 0s forwards;
}
.chat-thread > *:nth-child(10) .typing-indicator,
.chat-thread > * > .message:nth-child(10) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 12s forwards;
}
.chat-thread > *:nth-child(10) .typing-indicator span,
.chat-thread > * > .message:nth-child(10) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(10) .typing-indicator span:nth-of-type(1),
.chat-thread > * > .message:nth-child(10) .typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(10) .typing-indicator span:nth-of-type(2),
.chat-thread > * > .message:nth-child(10) .typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(10) .typing-indicator span:nth-of-type(3),
.chat-thread > * > .message:nth-child(10) .typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}

.message {
  display: flex;
  margin: 1rem 0;
  width: 100%;
}
/* .message::after {
  content: "";
  position: absolute;
  left: 75px;
  border-left: 25px solid transparent;
  border-right: 10px solid transparent;
  border-top: 25px solid #ECF0F1;
} */
.message + .message {
  margin-top: 0;
}
.message .avatar {
  width: 75px;
  height: 75px;
  background: url("http://lorempixel.com/75/75");
  margin-right: 20px;
  border-radius: 50%;
}
.message .message-content {
  display: flex;
  align-items: center;
  padding: 1em 1.5em;
  background: #ADC1E0 ;
  border-radius: 20px 20px 20px 5px;
  height: 52px;
}
.message.message-reply {
  justify-content: flex-end;
}
.message.message-reply .avatar {
  margin: 0 0 0 20px;
}
.message.message-reply .message-content {
  float: right;
  background-color: #0076FF;
  color: #fff;
}
.message.message-reply .message-content p:before {
  content: "";
  position: absolute;
  left: auto;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #0076FF;
}

.chat-actions {
  text-align: center;
  align-items: center;
}
.chat-actions button {
  margin-top: 1rem;
}

@keyframes appear {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  99% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    width: auto;
    height: auto;
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@keyframes fadeIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.container .card {
  width: 224px;
}


.bot-msg-bubble-container {
    position: relative;
}

.top-to-bottom {
    position: absolute;
    right: 42px;
    width: 20px;
    color: #000;
    z-index: 100000;
    border-radius: 5px;
    padding: 5px 15px;
    background-color: #a5b0cc;
    margin-bottom: 5px;
    bottom: 10px;
}

.top-to-bottom img {
    width: 20px;
    padding-right: 5px;
    right: 7px;
    top: 1px;
    position: relative;
}

.bot-msg-bubble-container:before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    z-index: -1;
    border-radius: 21px 21px 21px 10px;

}


.bot-msg-bubble-container:before {
    background-image: none;
  }

.response-wrapper {
    text-wrap: wrap;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
}

.svg-container:hover .cls-1 {
    fill: #FF0000 !important;
    /* Change fill color on hover */
}

.typewriter-text {
    left: 0;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 1.5s forwards;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
}

.copy {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.copy-img:hover {
    border-radius: 10px;
    background: #EAEDF1;
}

.copy img {
    cursor: pointer;
    padding: 7px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -58px;
    left: -40px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}



.copytool {
    position: relative;
}

.copytool .copytooltext {

    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    bottom: 116%;
    left: -18%;
}

.copytool .copytooltext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

@keyframes typing {
    0% {
        left: -100px;
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        left: 0px;
        opacity: 1;
    }
}


.loading-text {
    overflow: hidden;
    opacity: 0;
    width: 0;
    animation: loadingAnimation .8s forwards, scaleAnimation .8s forwards;
}

@keyframes loadingAnimation {
    0% {
        width: 0;
        opacity: 0;
    }

    50% {
        width: 0;
    }

    100% {
        width: 185px;
        opacity: 1;
    }
}

@keyframes scaleAnimation {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}


/* .element-to-move {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2s steps(40, end);
  } */



  .chat-suggesst-title-inner .video-bg  {
    width: 48%;
    object-fit: cover;
    } 
.answer-for-questions {
    word-wrap: break-word;
    align-items: center;
    background: #0c2159;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    overflow-wrap: anywhere;
    text-align: left;
    white-space: pre-wrap;
    width: auto;
    margin: 0;
}

.bot-msg-text,
.voice-indicator {
    align-items: center;
    display: flex;
    justify-content: center;
}

.bot-msg-text {
    max-width: 65ch;
    padding-left: 0px;
    padding-top: 10px;
}

.output-area .q-and-a-container .main-q-a-box {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.output-area .q-and-a-container .main-q-a-box {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 100%;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
        padding-right: 0px;
        margin-left: 30px;
    }
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
    display: flex;
    align-items: self-start;
    padding-right: 0px;
    justify-content: flex-end;
}

.left-client-msg-bubble {
    justify-content: flex-start !important;
    margin-left: 0px !important;
    /* margin-bottom: -30px; */
}

.left-client-msg-bubble .client-questions {
    border-radius: 25px 25px 25px 0px !important;
}

.bacground-client-msg-bubble {
    margin-bottom: 0px !important;
}

.bacground-client-msg-bubble {
    background: #ADC1E0 !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 12px !important;
}

.loading-area {
    display: grid;
    place-items: center;
    height: 100%;
    margin: 0px;
    padding: 0px;
    line-height: 0
}

.loader {
    display: flex;
    flex-direction: row;
}

.loading-text {
    padding: 12px 0px 0px 0px;
    margin: 0px 4px 3px 0px;
    color: #232D72;
}

.loading-model {
    position: relative;
    width: 22px;
}

.loading-model img {
    width: 27px;
    height: auto;
    position: absolute;
    top: -5px;
    right: 4px;
}

.loading-area .loader .loading-sphere {
    color: #232D72;
    background: #232D72;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: scale(0);
    animation: animate 1.5s ease-in-out infinite;
    display: inline-block;
    margin: 9px 4px 4px 4px;
}

.loading-area .loader .loading-sphere:nth-child(1) {
    color: #232D72;
    background: #232D72;
    animation-delay: 0s
}

.loading-area .loader .loading-sphere:nth-child(2) {
    color: #232D72;
    background: #232D72;
    animation-delay: 0.2s
}

.loading-area .loader .loading-sphere:nth-child(3) {
    color: #232D72;
    background: #232D72;
    animation-delay: 0.4s
}

.loading-area .loader .loading-sphere:nth-child(4) {
    animation-delay: 0.6s
}

.loading-area .loader .loading-sphere:nth-child(5) {
    animation-delay: 0.8s
}

.loading-area .loader .loading-sphere:nth-child(6) {
    animation-delay: 1s
}

.loading-area .loader .loading-sphere:nth-child(7) {
    animation-delay: 1.2s
}

@keyframes animate {

    0%,
    100% {
        transform: scale(0.2);
        background-color: #232D72
    }

    40% {
        transform: scale(1);
        background-color: #232D72
    }

    50% {
        transform: scale(1);
        background-color: #232D72
    }
}

.bot-msg-bubble .play-button-container button img {
    background: #fff;
    border-radius: 100%;
    height: 34px;
    margin: auto;
    opacity: .95;
    overflow: hidden;
    position: relative;
    transition: opacity .3s, box-shadow .3s;
    width: 34px;
}

.bot-msg-bubble .play-button-container button {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 38px;
    padding: 0;
    text-align: center;
    width: 38px;
}

.bot-msg-bubble .play-button-container {
    margin-top: 1px;
    min-width: 38px;
}

.bot-msg-bubble-container {
    background: #0c2159;
    border-radius: 21px 21px 21px 10px;
    display: flex;
    flex-direction: column;
    padding: 15px 18px 15px 15px;
}


.bot-msg-bubble-container::after {
    border-top-left-radius: 22px;
    border-top-right-radius: 41px;
    border-bottom-right-radius: 119px;
    border-bottom-left-radius: 15px;
    width: 70px;
    height: 0px;
    border-top: 54px solid #0c2159;
    border-right: 67px solid transparent;
    content: "";
    position: absolute;
    bottom: -13px;
    z-index: -1;
    left: 16px;
    margin-left: -5px;
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    position: relative;
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
}

@media (max-width: 767px) {
    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
        margin-left: 30px;
        padding-right: 0;
    }
    .chat-suggesst-img{
      display: none;
    }
    
}

@media (min-width: 767px) {
 
  .chat-suggesst-title-inner .video-bg  {
    display: none;
  }
}
.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
    align-items: self-start;
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
    word-wrap: break-word;
    align-items: center;
    background: #2175f3;
    border-radius: 21px 21px 5px 21px;
    color: #fff;
    display: flex;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    margin: 10px 0;
    max-width: 65ch;
    overflow-wrap: anywhere;
    padding: 8px 20px;
    text-align: left;
    white-space: pre-wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.output-area .q-and-a-container {
    text-wrap: wrap;
    white-space-collapse: collapse;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}



.output-area {
    border-radius: 12px 12px 0 0;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    margin: 0 auto;
    max-height: 100vh;
    min-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 36PX 0px 34PX;
    white-space: pre-wrap;
    width: 100%;
}

@media (max-width: 767px) {
  
    .dotmenu-wrapper {

        bottom: -48px !important;
        right: 40px !important;
    }

    .output-area {
        /* -webkit-backdrop-filter: blur(6.5px); */
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
    }

    .output-area {
        padding: 20px 20PX 0;
    }

    .output-area {
        min-height: 220px;
    }
}

.chat-suggesst-title {
    color: #293041;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 28.6px */
}

.chat-suggesst-img {
    width: 80px;
}

.colored-path {
    width: 30px;
}

.client-questions::selection {
    color: #0C2159;
    background: #fff;
}

.answer-for-questions::selection {
    color: #0C2159;
    background: #fff;
}

.play_loading_container svg {
    width: 90px;
    height: 34px;
    margin: auto;
    opacity: .95;
    overflow: hidden;
    position: relative;
}

.play_loading_container {
    display: flex;
    height: 34px;
    padding: 0;
    text-align: center;
    width: 90px;
}

.copy-img-hover:hover {
    opacity: .8 !important;
}

.play-button-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.play-button-menu {
    padding: 5px 5px 2px;
    background: #ffffff1a;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    height: 34px;
}

.play-button-menu:hover {

    background: #ffffff33;

}

.dotmenu-wrapper {
    border-radius: 10px;
    position: absolute;
    padding: 8px 9px;
    width: 160px;
    display: flex;
    bottom: -49px;
    right: 42px;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 1px 1px 2px rgba(0, 0, 0, .06), 0 1px 0px 4px rgba(0, 0, 0, .06);
}



.dotmenu-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    gap: 3px;
}

.dotmenu-menu {
    padding: 10px 5px 10px 10px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    gap: 10px;
}

.dotmenu-menu img {
    width: 18px;
}

.dotmenu-menu:hover {

    background: #eaeff9;
}

.profile-wrapper-chat{
  position: absolute;
  top: -40px;
}

.profile-container-img img{
width: 30px;
}
.profile-container-img{
  display: flex;
  margin: auto;
}
.profile-container-chat{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profile-container-name{
  font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.profile-container-img-user img{
  width: 30px;
}

.profile-container-chat-user{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.profile-wrapper-chat-user{
  position: absolute;
  top:-30px;
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
  position: relative;
}

.output-area .q-and-a-container .main-q-a-box {

  margin-top: 0px!important;
}

