

.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #5792ff;
  border-radius: 4px;
  margin-top: -176px;
  right: 0px;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  display: none;
}

.autocomplete-item {
  padding: 8px 16px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.input-container-wrapper {
  width: 100%;
  min-height: 55px;
  display: flex;
  justify-content: center;
  padding-left: 0px;
  align-items: center;
  flex-direction: column;
  margin: auto;
  background: hsla(0,0%,100%,0.9);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.06), 0 1px 1px -2px rgba(0,0,0,.06), 0 1px 5px 0 rgba(0,0,0,.06);
  border-radius: 18px;
  border: 1px solid ;
}

.input-container-wrapper:focus-within {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

}

.input-container-wrapper:hover {
  /* background-color: hsla(0,0%,100%,1)!important; */
}
#yourVideoDivId video{
  
    margin-right: 2px;
    margin-top: 50px;
    margin-bottom: 35px;
    position: absolute;
    width: 100%;
    top: -5px;
    object-fit: cover;
}
@media (max-width: 995px) {
  .start-right-column {
    /* display: none !important; */
  }
  .main-background-container .start-main-inner-container {
    border-radius: 0px!important;
  }
}

@media (max-width: 767px) {
  .main-background-container .start-main-inner-container {
    background: transparent!important;
  }
  .start-video {
    margin-top: 50px;
}

#yourVideoDivId video{
  object-fit: cover;
  max-width: 100vw !important;
  margin-right: 0px;
  margin-top: 60px;
  width: 100vw!important;
  top: -20px;
  height: unset;
}


}



.start-root{
  display: flex;
}




/* 


.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #5792ff;
  border-radius: 4px;
  margin-top: -176px;
  right: 0px;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  display: none;
}

.autocomplete-item {
  padding: 8px 16px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.input-container-wrapper {
  width: 100%;
  min-height: 55px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  align-items: center;
  margin: auto;
  background:#ffffffe8;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.06), 0 1px 1px -2px rgba(0,0,0,.06), 0 1px 5px 0 rgba(0,0,0,.06);
  border-radius: 18px;
  border: 1px solid ;
}

.input-container-wrapper:focus-within {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

}

.input-container-wrapper:hover {
  background-color: hsla(0,0%,100%,1)!important;
}
#yourVideoDivId video{
 
  margin-right: 2px;
  margin-top: 50px;
  margin-bottom: 35px;
  position: absolute;
  width: 100%;
  top: -140px;
}
@media (max-width: 995px) {
  .start-right-column {
   
  }
  .main-background-container .start-main-inner-container {
    border-radius: 0px!important;
  }
}

@media (max-width: 767px) {
  .main-background-container .start-main-inner-container {
    background: transparent!important;
  }
  .start-video {
    margin-top: 50px;
}

#yourVideoDivId video{
  max-width: 550px !important;
    margin-right: 0px;
    margin-top: 95px;
    top: -194px;
    height: 850px;
    min-height: calc(335vw / 2);
}
}



.start-root{
  display: flex;
} */