.central-greeting-down-arrow {
    bottom: 0;
    animation: loadingArrow 1.5s cubic-bezier(0.68, -0.55, 0.27, 1) forwards;
    }

@keyframes loadingArrow {
    0% {
        bottom: 0;
    }
    100% {
        bottom: -14px;
    }
}



.app {
    opacity: 0;
    position: relative;
    animation: bubbleAnimation 1s cubic-bezier(0.68, -0.55, 0.27, 1) both;
    transform-origin: bottom center;
    will-change: transform;
    perspective: 800px;
  }
  
  .app.loaded {
    opacity: 1;
    top: 0px;

  }
  
  @keyframes bubbleAnimation {
    0% {
      transform: scale(0) translate3d(0, 0, 0);
      opacity: 0;
      bottom: -70px;
      filter: blur(0px); 

    }
  
    /* 25% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.4;
      filter: blur(1px);
    }
  
    50% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.7;
      filter: blur(2px);
      z-index: 1000;

    }
  
    75% {
      transform: scale(0.9) translate3d(0, 0, 0);
      opacity: 0.9;
      filter: blur(1px);
      z-index: 1000;

    } */
  
    100% {
      transform: scale(1) translate3d(0, 0, 0);
      opacity: 1;
      bottom: -10px;
      filter: blur(0); /* Remove blur effect */
      z-index: 100;

    }
  }
  

.thumbnail-overlay svg{
    height: 307px;
    width: 320px;
}



.thumbnail-overlay {
    position: absolute;
    top: 72px;
    left: 0;
    width: 319px;
    height: 340px;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.thumbnail-overlay img {
    max-width: 100%;
    max-height: 100%;
}

.new-background-video-container:hover .thumbnail-overlay {
    opacity: 0; /* Hide the overlay on hover */
}

.start-central-navbar-backside-color{
    display: none;
}

.central-greeting-wrapper {
    position: relative;
    width: 84%;
    max-width: 330px;
    display: none;
}

.start-central-greeting-wrapper {
    display: block !important;
    }

.central-greeting-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    width: 100%;
    background: #FFF;
    stroke-width: 1px;
    stroke: #D4E3F9;
    filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.25));
    padding: 10px 10px 14px;
    position: absolute;
    z-index: 10;
    gap: 2px;
}

.central-greeting-user-name,
.central-greeting-text {
    color: #1A3054;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
}


.central-greeting-user-name {
    font-weight: 700;
}

.central-greeting-text {
    font-weight: 500;

}

.central-greeting-user img {
    width: 25px;
    padding-bottom: 1px;
}

.central-greeting-question {
    display: flex;
    align-items: center;
    gap: 5px;
}

.central-column-btn-right {
    width: 130px;
    height: 30px;
}


.central-greeting-ques {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.central-greeting-down-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #fff;
    margin: 0 auto;
    position: absolute;
        z-index: 100;
}

.new-background-video-container {
    position: relative;
    width: 100%;
    height: 390px;
    background: transparent !important;
    border-radius: 16px 0px 0px;
    display: flex;
    justify-content: center;
    top: -28px;
}

.main-three-colum-layout-container{

}

.new-background-video-container-chat{
    width: 100%;
    height: 100%;
    background: transparent !important;
    border-radius: 16px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-background-video-container-chat-video {
    width: 100%;
    object-fit: cover;
    max-height: 700px;
}

.desk_home_mobile_video_wrapper{
    position: relative;
    display: none;
}

.desk_home_video_wrapper {
    position: relative;
    /* height: 100%; */
    /* width: 60%; */
    /* top: 65px;
    left: -10px; */
}

.desk_home_video_container {
    height: 100%;
    width: 100%;
}

.left-column .video-wrapper .new-background-video-container-chat video {
    object-fit: cover;
    width: 100%;
    max-height: 700px;
}

.main-three-colum-layout .mobile-navbar-items li a .user-kps {
    width: 110% !important;
   
}

.main-three-colum-layout .mobile-navbar-items .user-li {
    border-radius: 16px !important;
}


.main-three-colum-layout .mobile-navbar-items .menu-li {
    background: none;

}

.main-three-colum-layout .mobile-navbar-items li a .menu-icon {
    width: 90% !important;
    margin-left: 0px;
}

/* start page */
.start-left-column {
    display: none !important;
}

.start-menu-li {
    border-radius: 25px !important;
}

.start-central-column {
    max-width: 1200px !important;
    width: 100vw !important;
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: flex-end; */
    background: transparent;
    flex-direction: column;
    height: 100%;
    padding-top: 38px;
    /* margin-bottom: 90px; */

    padding-bottom: calc(100vh - 641px);
}

.start-central-video-display {
    display: none;
}


.main-three-colum-layout .start-right-column {
    position: fixed !important;
    background-color: transparent !important;
    background: #e4efff !important;

}

.start-cental-video-wrapper {
    /* position: absolute; */
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    top: 38px;
    z-index: 0;
    width: 100%;
}

.video-wrapper{
    width: 100%;
}

.start-central-logo {
    margin: -18px 0px 10px;
    position: relative;
    z-index: 10000;

}

.start-video {
    width: 920px !important;
    margin-right: 0px;
    margin-top: 50px;
    height: 500px;
}


.start-mobile-text {
    color: #1E56A6;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 118.519% */
}

.start-bg-color{
   background:  #f5f8ff!important;
}

.start-main-three-colum-layout {
    align-items: center;
    background-color: #f5f8ff!important;
}

.start-mobile-text-wrapper {
    display: block !important;
    position: relative;
    z-index: 0;
}

.start-mobile-text-hide {
    display: none;
}

.start-mobile-text {
    margin: 0px;
}

.start-nav-img {
    display: none;
}

.start-nav-li {
    background: none !important;
}

.start-mobile-navbar-items {
    background: transparent !important;
}

.start-mobile-text-container {}

.center-column {
    display: flex;
}

.start-main-inner-container {
    /* align-items: flex-start !important; */
}

@media (max-width: 767px) {
    .desk_home_video_wrapper{
        display: none;
    }

    .desk_home_mobile_video_wrapper{
        display: block;
    }
    
    .start-cental-video-wrapper {
        top: 28px;
    }

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }

    .main-inner-container {
        background: #E4EFFF80 !important;
        box-shadow: none;
        /* backdrop-filter: blur(0); */

    }

    .start-main-background-container {
        height: 290px !important;
        top: 0px;
        position: relative;
    }

    .start-mobile-text-wrapper {
        margin-bottom: 15px;
    }

    .start-main-logo {
        width: 250px;
    }

    .loading-indicator {
        /* margin-bottom: -18px !important; */

    }

    .central-navbar-backside-color{
        width: 100%;
        height: 56px;
        top: 0;
        background: red;
        position: absolute;
    
    }

}



@media (min-width: 995px) {
    .mobile-navbar-items {
        display: none !important;
    }

    .right-column {
        border-radius: 0px !important;

    }
}

@media (max-width: 995px) {
    .mobile-navbar-items {
        /* background: #fff!important; */

    }
}

@media (min-width: 992px) {
    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0px;
        bottom: auto !important;
        left: auto !important;
        display: flex;
        align-items: center;
        z-index: 1000;

    }
}

.user-login-img {
    margin: auto 0 !important;
}

.main-three-colum-layout .navbar-items .user-login-img a img {
    width: 35px;
    /* margin-right: -45%; */
}

.sharebtn-img img{
    width: 20px;
}

.main-three-colum-layout .menu-logo-kps {
    width: 260px !important;
    max-width: 205px !important;
}

.right-column .navbar .navbar-items .menu-logo-kps-wrapper {
    height: 100% !important;
    padding: 0px !important;
    background: #fff !important;
    margin: 10px 0px 5px 0px !important;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.start-menu-logo-kps-wrapper {
    display: none;
}

.start-logo-profile {
    /* margin-top: 10px!important; */
}



.bold-text {
    font-weight: 800;
}


.right-column .navbar .navbar-items .start-menu-logo-wrapper {
    margin-left: 0px !important;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .main-three-colum-layout .navbar-items {

        max-width: 1200px;
    }

    .user-login-img {
        /* width: 10% !important; */
    }
}

@media screen and (min-width: 1280px) {
    .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
        max-width: 70ch !important;
    }

    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
        max-width: 70ch !important;
    }
}


.start-logo-profile-img {
    /* margin-right: -38% !important; */
}


.bacground-client-msg-bubble {
    margin-bottom: 0px !important;
}


.knightingale_avatar_bar img{
    width: 100%;
    position: relative;
    bottom: -4px;
    max-width: 55px;
    /* margin-top: 10px; */
}

.knightingale_avatar_bar_wrapper{
    height: 77px;
}

.knightingale_avatar_bar {
    height: 55px;
    display: flex;
    justify-content: left;
    /* padding-left: 20px; */
}

@media screen and (min-width: 767px) {
    .knightingale_avatar_bar {
        display: none;
    }
}


@media (max-width: 767px) {

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }}




/* 
.new-background-video-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 8px;
    width: 140%;
    height: 620px;
    max-height: 800px;
    background-image: linear-gradient(90deg, transparent 0 62%, #f5f8ff 70% 0%),
    linear-gradient(180deg, transparent 0 83%, #f5f8ff  95% 0%);
}

.new-background-video-container::before {
    z-index: 1;
    content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 800px;
    height: 620px;
    background-image: linear-gradient(90deg, #f5f8ff 0 2%, transparent 10% 98%),
    linear-gradient(180deg, #f5f8ff 0 2%, transparent 6% 50%);
} */



