body {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#root {
    max-width: 100vw;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;

}

.main-three-colum-layout {
    display: flex;
    padding: 0;
    max-width: 95vw;
    margin: auto;
    justify-content: center;

}


#root {
    display: flex;
    align-items: end;
}

.start-root {}

.start-root .main-three-colum-layout-wrapper {
    max-height: calc(100vh);
}

.output-area {
    max-height: calc(100vh - 182px);
}

@media (min-width: 768px) {


    #root {
        display: flex;
        align-items: end;
    }

    .start-root {}

    .start-root .main-three-colum-layout-wrapper {
        max-height: calc(100vh);
    }

    .main-three-colum-layout-wrapper {
        max-height: calc(100vh - 95px);
    }

    .output-area {
        max-height: calc(100vh - 182px);
        min-height: calc(100vh - 182px);
        display: flex;
        flex-direction: column-reverse;

    }


    .left-column {
        max-width: 440px;
    }

    .center-column {
        max-width: 1260px;
    }









    .main-three-colum-layout {
        max-width: 100vw;
    }

    .left-column .video-wrapper {
        left: 0px !important;
    }

    /* 

    .main-three-colum-layout {
        max-width: 95vw;
        position: relative;
    }

    .main-three-colum-layout .left-column {
        position: absolute;
    }
    .main-three-colum-layout-wrapper {
        max-height: calc(100vh - 45px);
    }

    .central-column-bbtn-wrapper {
top: 0;
    }

    .main-three-colum-layout {
        justify-content: space-between;
    }

    .left-column {
        width: 38%!important;
    max-width: 560px;
    }

    .left-column .video-wrapper video {
        z-index: 0;
    } */
    /* new style 02 09 */

    .left-column .video-wrapper video {
        width: 600px;
        height: 100% !important;
        top: 30px !important;
    }

    .center-column {
        max-width: 100vw;
        justify-content: space-between;
    }

    .main-three-colum-layout .left-column {
        position: absolute;
        max-width: 600px;
        width: 50%;
        z-index: 0;
    }

    .main-background-container {
        width:70%;
    }

    .main-three-colum-layout-wrapper {
        max-height: calc(100vh);
    }

    .output-area {
        /* min-height: calc(100vh - 203px)!important;
            max-height: calc(100vh - 203px)!important; */
    }
}

/* new style 02 09 */

@media (max-width: 992px) and (min-width: 768px) {
    .main-background-container {
        width: 100%;
    }

    .output-area {
        /* min-height: calc(100vh - 312px)!important;
    max-height: calc(100vh - 301px)!important; */
    }
}

@media (max-width: 1150px) and (min-width: 992px) {
    .main-three-colum-layout .left-column {
        width: 55% !important;
    }


    .output-area {

        border-radius: 0 !important;
    }

    .main-background-container .main-inner-containe {}

    .main-three-colum-layout {
        padding: 0 !important;
    }

    .main-background-container .main-inner-container {
        max-height: 730px !important;
        border-radius: 0 !important;

    }
}


.left-column {
    width: 40%;
    height: 100%;
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.left-column .video-wrapper {

    width: 100%;
    display: inline-block;
    position: relative;
    left: -100px;
    height: 100%;
    max-height: 95vh;
    overflow: hidden;
    border-radius: 12px 0px 0px 12px !important;
}

.left-column .video-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 8px;
    width: 140%;
    height: 100%;
    background-image: linear-gradient(90deg, transparent 0 56%, #f0f3f4 70% 0%);
}

.left-column .video-wrapper::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-image: linear-gradient(90deg, #f0f3f4  0 10%, transparent 60% 58%);
}

.left-column .video-wrapper .new-background-video {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 80px;
    left: 0%;
    border-radius: 12px;
    overflow: hidden;
    /* top: 50%;
  left: 50%;
  transform: translateX(-30%) translateY(-50%); */
}


.left-column .logo-wrapper {
    position: absolute;
    top: 10vh;
    width: 100% !important;
    z-index: 999;
}

.left-column .logo-wrapper a {
    width: 100% !important;
    text-align: center;
    margin: 0 auto;
    display: inline-block
}

.left-column .logo-wrapper a img {
    width: 240px !important;
    margin: 0 auto;
}


.main-three-colum-layout .center-column {
    width: 70%;
    overflow: hidden;
    /* flex-grow: .3; */
    /* margin: 0 0 0 -2px; */
    /* max-width: 75%; */
    /*padding-right: 50px;*/
}

.main-background-container .main-inner-container .kps-chat-form {
    /* max-width: 860px; */
}

.right-column {
    position: fixed !important;
    right: 12px !important;
    left: auto !important;
    top: 0;
    z-index: 999;
    width: 40px !important;
    /* background: transparent !important; */

    display: flex;
    align-content: center;
    align-items: center;

}

.main-three-colum-layout .navbar-items {
    /* justify-content: flex-start; */
    margin-top: 0;
    max-width: 1400px;
    margin: auto;
    flex-direction: row;
}

.main-three-colum-layout .navbar {
    background: transparent !important;
    max-height: 95vh;
}

.right-column .navbar .navbar-items li {
    width: 30%;
    height: 35px;
    border-radius: 18px;
    /* background: #cbe7ffcf !important; */
    margin: 0px 0px 7px 0px;
}


.main-inner-container {
    /* background: rgba(255, 255, 255, 0.7) !important;*/

    /* From https://css.glass */
    background: transparent !important;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    /* backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px); */

    width: calc(100% - 60px);
}


/*chat-responses*/

/* .main-background-container .main-inner-container .kps-chat-form .input-container button.submit-button {
    margin: 3px 1px 3px 1px;
    border-radius: 25px !important;
    width: 50px;
    height: 46px;
} */

/* .main-background-container .main-inner-container .kps-chat-form .input-container button.submit-button img {
    max-width: 50px;
    transition: box-shadow 0.3s ease;
    border-radius: 25px;
} */

.main-background-container .main-inner-container .kps-chat-form .input-container button.submit-button img:hover {
    /* box-shadow: 0px 0px 10px #00000070; */
}



.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    position: relative;
    /* margin-right: 30px */
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
    /* padding: 15px 20px 15px 20px; */
    margin: 0px
}

.bot-msg-bubble .play-button-container {
    margin-top: 1px;
    min-width: 38px;
}

.bot-msg-bubble .play-button-container button {
    background: transparent !important;
    border: none !important;
    display: flex;
    text-align: center;
    cursor: pointer;
    border-radius: 15%;
    padding: 0px;
    width: 90px;
    height: 34px;
    /* position: absolute;
    height: 52px;
    text-align: center;
    cursor: pointer;
    width: 52px;
    padding: 1px !important;
    background: transparent !important;
    border: none !important;
    top: -10px;
    left: auto;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    box-shadow: 18px 20px 25px 20px rgba(192, 219, 241, 0.9)inset!important; */
}

.bot-msg-bubble .play-button-container button::after {
    /* content: "";
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    transform: scale(0);
    animation: ripple 0.5s linear; */

    border-radius: 100%;
    height: 34px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 34px;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.bot-msg-bubble .play-button-container button:hover {

    /* box-shadow: 0px 0px 14px 3px rgba(57, 153, 243, 0.9)inset!important; */
}

.bot-msg-bubble .play-button-container button img {
    border-radius: 15%;
    height: 34px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90px;
    background: transparent;
    transition: opacity 0.3s, box-shadow 0.3s;
    opacity: .95;
}

.bot-msg-bubble .play-button-container button img:hover {
    opacity: 1;

}

.main-background-container .main-inner-container .kps-chat-form .input-container .chat-logo-image {
    max-width: 50px;
    border-radius: 25px;
}

/* .main-background-container .main-inner-container .kps-chat-form .input-container button {
    padding: 1px 1px 2px 1px;
    margin: auto auto -2px;
} */


.new-mobile-background-video {
    display: none;
}

@media (min-width: 3000px) {
    #root {
        max-width: 2600px;
    }

    .left-column .video-wrapper,
    .main-background-container .main-inner-container,
    .main-three-colum-layout .navbar {
        max-height: 1200px;
    }

    .left-column .logo-wrapper {
        top: 25vh;
    }
}

@media (min-width: 1920px) {
    #root {
        /* max-width: 1900px; */
    }

    .main-three-colum-layout .left-column {
        width: 30%;
    }

    .main-three-colum-layout .center-column {
        width: 70%;
        /* max-width: 1160px; */
    }

    .main-background-container .main-inner-container .kps-chat-form {
        /* max-width: 1160px; */
    }



}

@media (min-width: 992px) {
    .main-background-container .main-inner-container .kps-chat-form .input-container {
        margin: 0px 0px 15px 0px;
    }

    .start-main-background-container {
        position: unset;
        height: 175px;
    }

    .main-background-container .main-inner-container .start-form {
        position: relative;
        max-width: 100% !important;
        bottom: 25px;
        margin: 0px;
    }
}

@media (max-width: 1150px) and (min-width: 992px) {

    .main-three-colum-layout {
        padding: 1em 1em;
    }

    .main-three-colum-layout .left-column {
        width: 30%;
    }

    .main-three-colum-layout .center-column {
        width: 70%;
        /* max-width: 510px; */
    }

    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0px;
        bottom: auto;
        left: auto !important;
        display: flex;
        align-items: center;
        z-index: 999;
    }

    .left-column .video-wrapper,
    .main-background-container .main-inner-container,
    .main-three-colum-layout .navbar {
        max-height: 700px;
    }

    .left-column .logo-wrapper {
        top: 12vh;
    }

    .left-column .video-wrapper .new-background-video {

        top: 130px;
    }


}





/* Portrait-iPad-pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

    .left-column .logo-wrapper {
        top: 26vh;
    }

}





@media (max-width: 995px) and (min-width: 768px) {
    .profile-wrapper {
        left: -165px;
    }

    .central-column-bbtn-wrapper {
        top: 69px;
    }

    .central-navbar-backside-color {
        width: 100%;
        height: 70px;
        top: 0;
        background: red;
        position: absolute;
    }

    .main-three-colum-layout .center-column {
        width: 100% !important;
        margin: 0 auto !important;
        padding-right: 0;
        max-width: 100%;
    }

    .main-background-container .main-inner-container {
        max-height: 100vh;
        border-radius: 0;
    }

    .main-background-container .main-inner-container .kps-chat-form {
        position: relative;
        max-width: 100% !important;
        bottom: 48px;
        margin: 0px;
        display: flex;
        padding: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .main-three-colum-layout {

        max-width: 100vw;

    }

    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;

    }

    .main-three-colum-layout .start-right-column {
        background-color: transparent !important;
    }

    .main-three-colum-layout .right-column .navbar {
        width: 100% !important;
    }

    .main-three-colum-layout .mobile-navbar-items li.logo-li {
        width: auto !important;
    }

    .main-three-colum-layout .right-column .mobile-navbar-items {
        width: 100% !important;
    }

    .mobile-navbar-items li {
        width: 37px !important;
        height: 37px !important;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        margin: 0 auto 14px auto;
    }

    .mobile-navbar-items li.logo-li {
        width: auto !important;
        background: transparent !important;
        padding: 0 !important;
    }

    .main-three-colum-layout .mobile-navbar-items li a {
        display: flex;
        text-align: center;
        align-items: center;
    }

    .main-three-colum-layout .mobile-navbar-items li a img {
        height: auto;
        width: auto !important;
        margin: 0 auto;

    }

    .main-three-colum-layout .mobile-navbar-items .menu-kps {
        width: 37px !important;
    }

    .main-three-colum-layout .mobile-navbar-items li a img.logo-kps {
        max-width: 200px !important;
    }

    .main-three-colum-layout .mobile-navbar-items li {
        margin: 15px 0;
        padding: 0;
    }

    .new-mobile-background-video {
        display: block;
        width: 120%;
        height: auto;
        position: fixed;
        top: 50%;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 767px) {
    .main-three-colum-layout .center-column {

        overflow-y: auto;
        /* flex-grow: .3; */
        /* margin: 0 0 0 -2px; */
        /* max-width: 75%; */
        /*padding-right: 50px;*/
    }

    .output-area {
        padding: 20px 20PX 0PX 20PX;
    }

    .main-inner-container {
        background: transparent !important;
        box-shadow: none;
        /* backdrop-filter: blur(0); */
    }

    .main-three-colum-layout .right-column {
        position: absolute !important;
        width: 100% !important;
        right: 0 !important;
        background-color: #fff !important;
        border-radius: 0px 0px 20px 20px;
        top: 0;
        z-index: 100000;
        box-shadow:
            0px 1px 1px 0px hsla(0, 0%, 0%, 0.12), 0px 2px 11px -1px hsla(0, 0%, 0%, 0.02), 0px 1px 8px 0px hsla(0, 0%, 0%, 0.12)
    }

    .start-mobile-navbar-items .logo-li {
        margin-left: 14px !important;
    }

    .main-three-colum-layout .start-right-column {
        background-color: transparent !important;
    }

    .main-three-colum-layout {
        max-width: 100vw;
        /* overflow: scroll; */
        /* background: #E4EFFF80; */
    }

    .main-three-colum-layout .right-column .navbar {
        width: 100% !important;
    }

    .main-three-colum-layout .mobile-navbar-items li.logo-li {
        width: auto !important;
    }

    .main-three-colum-layout .right-column .mobile-navbar-items {
        width: 100% !important;

    }

    .mobile-navbar-items li {
        width: 40px !important;
        height: 35px !important;
        border-radius: 25px;
        /* background:rgba(0, 0, 0, 0.5); */
        margin: 0 auto 14px auto;
    }

    .mobile-navbar-items li.logo-li {
        width: auto !important;
        background: transparent !important;
        padding-left: 0px !important;
    }

    .main-three-colum-layout .mobile-navbar-items li a {
        display: flex;
        text-align: center;
        align-items: center;
    }

    .main-three-colum-layout .mobile-navbar-items li a img {
        height: auto;
        width: auto !important;
        margin: 0 auto;
    }

    .main-three-colum-layout .mobile-navbar-items li a img.menu-kps {
        width: 30px !important;
        opacity: 0.6;
    }

    .main-three-colum-layout .mobile-navbar-items li a .menu-kps:hover {
        width: 30px !important;
        opacity: 1;
    }

    .main-three-colum-layout .mobile-navbar-items li a img.logo-kps {
        max-width: 190px !important;
    }

    .main-three-colum-layout .mobile-navbar-items li {
        padding: 0;
        margin: 8px 0px 8px;
    }

    .main-three-colum-layout .center-column {
        width: 100%;
        margin: 0 !important;
        padding-right: 0;
        max-width: 100%;
        /* overflow: scroll; */
        /* justify-content: center; */
        padding-bottom: 10px;
        padding-top: 0px;
    }

    .start-central-column {
        padding-bottom: 20px;
    }

    .output-area {
        /* From https://css.glass */
        /* background: rgba(255, 255, 255, 0.4) !important; */
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0px;
        /* padding-bottom: 10px; */
        box-shadow: none;
        /* backdrop-filter: blur(6.5px); */
        /* -webkit-backdrop-filter: blur(6.5px); */
    }

    .right-column {
        width: 100% !important;
    }

    .main-background-container .main-inner-container .kps-chat-form .mobile-voice-container {
        position: relative;
        background-color: #dbe0e8;
        display: none;
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container {
        background-color: transparent !important;
        border-radius: 0 0 12px 12px;
        width: 97% !important;
        padding-bottom: 1px;
    }

    .new-mobile-background-video {
        display: block;
        width: auto;
        height: 102%;
        position: fixed;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }


}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media (max-width: 767px) {
        .main-background-container .main-inner-container .kps-chat-form {}
    }
}

.voice-btn-kps .ripple-btn img {
    border-radius: 24px;
    flex-shrink: 0;
}

/* .main-background-container .main-inner-container .kps-chat-form .input-container button.submit-button img:hover {
    box-shadow: 0px 0px 0px 3px rgba(191, 223, 252, .9) ;
} */

.main-background-container .main-inner-container .kps-chat-form .input-container button.ripple-btn img.chat-logo-hover:hover {
    /* background-color: hsla(0, 100%, 100%, 0.08);
    box-shadow:
        0px 3px 4px 0px hsla(0, 0%, 0%, 0.14),
        0px 3px 3px -2px hsla(0, 0%, 0%, 0.12),
        0px 1px 8px 0px hsla(0, 0%, 0%, 0.2); */
}

/* .main-background-container .main-inner-container .kps-chat-form .input-container button.ripple-btn img.chat-logo-hover,
.main-background-container .main-inner-container .kps-chat-form .input-container button.ripple-btn img:active {
    background-color: hsla(0, 100%, 100%, 0.09);
    box-shadow:  0px 0px 3px 1px hsla(0, 0%, 8%, 0.2);
} */

/* .main-background-container .main-inner-container .kps-chat-form .input-container button.ripple-btn img {
    transition: box-shadow 0.3s ease-in-out;
} */

/* .main-background-container .main-inner-container .kps-chat-form .input-container .chat-logo-image:hover {
    box-shadow: 0px 0px 14px 20px rgba(191, 223, 252, .9)inset!important;
    border-radius: 25px !important;
    padding: 4px 2px!important;
} */

.start-grid-center-column {
    width: 100% !important;
    max-width: 1212px !important;
}

/* edited f */



@media (min-width: 768px) {
    .main-background-container {
        /* position: absolute; */
        right: 0;
    }

    .main-three-colum-layout .left-column {
        left: 0px;
    }
}