.count-container{
  color: black;
}
.input-container-inner{
  width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    align-items: center;
    margin: auto;
    border-radius: 18px;
}

.count-wrapper{
  width: 100%;
  margin-bottom: 5px;
}

.count-container{
  padding-left: 20px;
  line-height: 16px;
}
.sendtool {
    position: relative;
  }
  
  .sendtool .sendtooltext {
    text-wrap: nowrap;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    bottom: 113%;
    left: 151%;
    margin-left: -109px;
  }
  
  .sendtool .sendtooltext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
 