.profile-wrapper {
    width: 200px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    left: -80px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.profile-link-wrapper{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 12px;
    border-radius: 6px;
}
.profile-link-wrapper {
    transition: background 0.3s ease; 
  }

.profile-link-wrapper:hover{
   background: #00000014;
 
}

.user-login-img{
    display: flex;
    justify-content: center; 
}

.profile-container {}

.profile-innner-container {
    text-align: left;
    margin: 10px 0px 0px;
    padding: 10px 8px 10px;
}

.profile-innner-container hr {
    border-top: 1px solid #e0e0e0;
    margin: 10px 5px;;
}

.profile-innner-container a {
    color: #1A3054;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.up-arrow{
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #fff;
    margin: 0 auto;
    position: absolute;
    top: -10px;
    right: 9px;
}



.main-container-login {
    opacity: 0; 
    animation: fadeIn 0.35s ease-in-out .35s forwards; 
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }



  .main-three-colum-layout .navbar-items li .profile-button {
    text-decoration: none;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
}

.main-three-colum-layout .navbar-items .user-login-img .profile-button img {
  width: 35px;
  /* margin-right: -45%; */
}
.main-three-colum-layout .navbar-items li .profile-button img {
  width: 100%;
  height: 100%;
  max-width: 40px;
}